import React, { useContext, useEffect, useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useDomainC } from './Domain';

const AuthContext = React.createContext()

export const useAuth = () =>{
    return useContext(AuthContext)
}

export const AuthProvider = ({children}) => {

    const [currentUser, setCurrentUser] = useState()
    const [isAdmin, setIsAdmin] = useState(false)
    const [userID, setUserID] = useState()
    const [user_name, setUser_name] = useState()
    const [error, setError] = useState('')
    const navigate = useNavigate();

    const {domain, domain_host} = useDomainC() 

    useEffect(() => {
        const unsub = submitLogin() 
        return unsub
    }, [])

    const submitLogin = async (email, password) => {
        if(email && password){
            const requestOptions = {
            method: "POST",
            withCredentials: true,
            headers: { "accept": "application/json",
                "Content-Type": "application/x-www-form-urlencoded" },
            data: JSON.stringify(
                `grant_type=&username=admin&password=123!%40%23qwe&scope=&client_id=&client_secret='`
            ),
            };
            console.log("ok")

            await axios(requestOptions).
                then(
                    res => 
                        {
                            console.log(res)
                            setError("")
                            navigate(
                                '/dashboard'
                            )
                            setCurrentUser(res.message);
                        }
                ).catch(
                    err => {
                            setCurrentUser()
                            setError("err.detail")
                            console.log(err)
                    }
                );
        }
      };

      const login = async (email, password) => {

        const requestOptions = {
            method: "POST",
            url: domain+ "admin/login",
            withCredentials: true,
            headers: {
                "Access-Control-Allow-Origin": domain_host,
                "Content-Type": "application/x-www-form-urlencoded" },
            data: JSON.stringify(
                `grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret='`
            ),
        };
        
        await axios(requestOptions).
        then(
            res => 
            {
                setError("")
                navigate('/dashboard')
                setCurrentUser(res.data[1].message);
                setUser_name(res.data[1].username)
                localStorage.setItem("username", res.data[1].username)
                setIsAdmin(res.data[1].is_admin)
                localStorage.setItem("is_admin", res.data[1].is_admin)
                setUserID(res.data[1].user_id)
                localStorage.setItem("user_id", res.data[1].user_id)
            }
        ).catch(
            err => {
                setCurrentUser()
                setUser_name()
                setIsAdmin()
                setUserID()
                if(err.message ==='Network Error' ){
                    setError( err.message)
                }
                else{
                    // setError(err.response.data.detail)
                    console.log("errorororor", err)
                }
            }
        );
      };

      const signup = async (email, password) => {
          console.log("pl",email, password)

        const requestOptions = {
            method: "POST",
            url: domain+ "admin/users/new",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json" },
            data: JSON.stringify({
                "email":  email,
                "password": password
            })
        };
        
        await axios(requestOptions).
        then(
            res => 
            {
                setError("")
                navigate('/')
                
            }
        ).catch(
            err => {
                setCurrentUser()
                if(err.message ==='Network Error' ){
                    setError( err.message)
                }
                else{
                    setError(err.response.data.detail)
                }   
            }
        );
      };
    
    const value = {
        currentUser,
        submitLogin,
        login,
        error,
        signup,
        isAdmin,
        userID,
        user_name,
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    )
}


