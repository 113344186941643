import React, { useState, useEffect } from 'react'
import Navbar from './Navbar';
import UserTable from './usertable/UserTable';
import Table from './table/Table'
import getAllUSers from '../assets/JsonData/getAllUsers.json'
import { useSideC } from '../contexts/SidebarContext';
import { MDBCol, MDBFormInline, MDBBtn, MDBIcon } from "mdbreact";
import { useNavigate } from 'react-router';
import axios from 'axios';
import { BsWindowSidebar } from 'react-icons/bs';
import { useDomainC } from '../contexts/Domain';
import '../assets/css/layout.css'

const customerTableHead = [
    'user id',
    'api key',
    'domain',
    'last update',
    'requests',
    'active',
    "delete",
    "add domain"
]
const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }


const renderHead = (item, index) => <th key={index}>{item}</th>

const ApisTable = ({data, refreshMethod}) => {
    const {sidebarActive} = useSideC()
    const [apis, setApis] = useState()
    const [load, setLoad] = useState(false)
    const navigate = useNavigate()
    const {domain} = useDomainC()

    const handleApiDelete = async (api_key, user_id) => {
        const requestOptions = {
            method: "POST",
            url: `${domain}admin/apikey/delete/${api_key}`,
            withCredentials: true,
            headers: {
                "accept": "application/json",
            },
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    alert(`API: ${res.data.deleted} is deleted`)
                    window.location.reload()
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }else{
                    console.log("error", err.response)
                }
            }
        );
    }

    const handleAddDomain = async (api, domain_name) => {
        var url_path = `${domain}admin/apikey/new/domain/${api}`
        const requestOptions = {
            method: "POST",
            url: url_path,
            withCredentials: true,
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json" 
            },
            data: JSON.stringify({
                "domain": domain_name
            })
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    alert(`New domain added to ${res.data.api_key}`)
                    window.location.reload()
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }else{
                    console.log(url_path)
                    console.log("error", err.response)
                }
            }
        );
    }

    const renderBody = (item, index) => (
        <tr key={index}>
            <td>{item.user_id}</td>
            <td className="api_key_all_lower"
             onClick={()=>{navigator.clipboard.writeText(item.api_key); alert(`Api: ${item.api_key} copied to clipboard!`)}}>
                {item.api_key} </td>
            <td className="api_domain_all_lower">{item.domain}</td>
            <td>
                {formatDate(item.updated_at)}
            </td>
            <td>
                {`${item.total_queries}/${item.max_queries}`}
            </td>
            <td>
                {item.is_active.toString()}
            </td>
            <td>
            <button 
                    type="button" 
                    className={`btn btn-danger`}
                    onClick={()=>{handleApiDelete(item.api_key, item.user_id)}}>
                        {"Delete"}
                </button>
            </td>

            <td>
            <button 
                    type="button" 
                    className={`btn btn-info`}
                    onClick={()=>{
                            var domain = prompt("Please enter a new domain for this api");
                            handleAddDomain(item.api_key, domain)
                        }}>
                        {"Add Domain"}
                </button>
            </td>
        </tr>
    )

    
    return (
        <div>
            <div className="card__body">
                <Table
                    limit='10'
                    headData={customerTableHead}
                    renderHead={(item, index) => renderHead(item, index)}
                    bodyData={data}
                    renderBody={(item, index) => renderBody(item, index)}
                    />
            </div>
        </div>
    )
}


export default ApisTable
