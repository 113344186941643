import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar';
import UserTable from '../components/usertable/UserTable';
import Table from '../components/table/Table'
import getAllUSers from '../assets/JsonData/getAllUsers.json'
import { useSideC } from '../contexts/SidebarContext';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { MDBCol, MDBFormInline, MDBBtn, MDBIcon } from "mdbreact";
import { useDomainC } from '../contexts/Domain';

const customerTableHead = [
    'id',
    'email',
    'status',
    'created at',
    'Activate/Deactivte',
    'Delete'
]


const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }


const renderHead = (item, index) => <th key={index}>{item}</th>



const User = ({data}) => {
    const {sidebarActive} = useSideC()
    const [user1, setUser1] = useState(null)
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [filter, setFilter] = useState(false)
    const [userIdFromSearch, setUserIdFromSearch] = useState()
    const {domain} = useDomainC()

    const renderBody = (item, index) => (
        <tr key={index}>
            <td>{item.id}</td>
            <td>{item.email}</td>
            <td>
                {item.is_active == true ? "Active" : "Not Active"}
            </td>
            <td>
                {formatDate(item.created_at)}
            </td>
            <td>
                <button 
                    type="button" 
                    className={`btn btn-${item.is_active==true ? "warning" : "success"}`}
                    onClick={ ()=>{handleDeactivateUser(item.id, item.is_active)}}>
                        {item.is_active ? "Deactivate": "Activate"}
                </button>
            </td>
            <td>
                <button 
                    type="button" 
                    className={`btn btn-danger`}
                    onClick={()=>{handleDeleteUser(item.id)}}>
                        {"Delete"}
                </button>
            </td>
        </tr>
    )

    const handleDeactivateUser = async (id, user_active) => {
        var url = "";
        if (user_active){
            url = domain + "admin/users/deactivate/"
        }else {
            url = domain + "admin/users/activate"
        }
        const requestOptions = {
            method: "POST",
            url: url+ id.toString(),
            withCredentials: true,
            headers: {
            "accept": "application/json",
            }
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    alert(`User: ${res.data.email} with id: ${res.data.id} is ${user_active ? "deactivated": "activated"}`)
                    window.location.reload()
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }else{
                    console.log("error", err.response)
                }
                console.log("erro")
            }
        );
    }

    const handleDeleteUser = async (id) => {
        const requestOptions = {
            method: "POST",
            url: domain + "admin/users/delete/"+ id.toString(),
            withCredentials: true,
            headers: {
            "accept": "application/json",
            }
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    alert(`User with id: ${res.data.deleted} is deleted!`)
                    window.location.reload()
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }else{
                    console.log("error", err.response)
                    window.location.reload()
                }
                
            }
        );
    }



    return (
        <div>
            <div className="card__body">
                <Table
                    limit='10'
                    headData={customerTableHead}
                    renderHead={(item, index) => renderHead(item, index)}
                    bodyData={data}
                    renderBody={(item, index) => renderBody(item, index)}
                    />
            </div>
        </div>
    )
}

export default User
