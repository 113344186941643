import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar';
import UserTable from '../components/usertable/UserTable';
import Table from '../components/table/Table'
import getAllUSers from '../assets/JsonData/getAllUsers.json'
import { useSideC } from '../contexts/SidebarContext';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { MDBCol, MDBFormInline, MDBBtn, MDBIcon } from "mdbreact";
import User from '../pages/User';
import {Button, Container} from 'react-bootstrap'
import { useDomainC } from '../contexts/Domain';
import { useAuth } from '../contexts/AuthContext';
import UserNoAdmin from '../components/UserNoAdmin';
import '../assets/css/layout.css'

const customerTableHead = [
    'id',
    'email',
    'status',
    'created at',
    'Activate/Deactivte',
    'Delete'
]


const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }


const renderHead = (item, index) => <th key={index}>{item}</th>



const Users = () => {
    const {sidebarActive} = useSideC()
    const [user1, setUser1] = useState(null)
    const navigate = useNavigate()
    const [load, setLoad] = useState(false)
    const [filter, setFilter] = useState(false)
    const [userIdFromSearch, setUserIdFromSearch] = useState()
    const [userObjSearch, setUserObjSearch] = useState()
    const {domain} = useDomainC()
    const {isAdmin} = useAuth()

    const renderBody = (item, index) => (
        <tr key={index}>
            <td onClick={()=>{navigator.clipboard.writeText(item.id); alert(`User Id: ${item.id} copied to clipboard!`)}}>{item.id }</td>
            <td>{item.email}</td>
            <td 
            // className={item.is_active == true ? "active_status" : "not_active_status"}
            >
                {item.is_active == true ? "Active" : "Not Active"}
            </td>
            <td>
                {formatDate(item.created_at)}
            </td>
            <td>
                <button 
                    type="button" 
                    className={`btn btn-${item.is_active==true ? "warning" : "success"}`}
                    onClick={ ()=>{handleDeactivateUser(item.id, item.is_active)}}>
                        {item.is_active ? "Deactivate": "Activate"}
                </button>
            </td>
            <td>
                <button 
                    type="button" 
                    className={`btn btn-danger`}
                    onClick={()=>{handleDeleteUser(item.id)}}>
                        {"Delete"}
                </button>
            </td>
        </tr>
    )


    const handleDeactivateUser = async (id, user_active) => {
        var url = "";
        if (user_active){
            url = domain + "admin/users/deactivate/"
        }else {
            url = domain + "admin/users/activate"
        }
        const requestOptions = {
            method: "POST",
            url: url+ id.toString(),
            withCredentials: true,
            headers: {
            "accept": "application/json",
            }
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    alert(`User: ${res.data.email} with id: ${res.data.id} is ${user_active ? "deactivated": "activated"}`)
                    window.location.reload()
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }else{
                    console.log("error", err.response)
                }
                console.log("erro")
            }
        );
    }

    const handleDeleteUser = async (id) => {
        const requestOptions = {
            method: "POST",
            url: domain + "admin/users/delete/"+ id.toString(),
            withCredentials: true,
            headers: {
            "accept": "application/json",
            }
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    alert(`User with id: ${res.data.deleted} is deleted!`)
                    window.location.reload()
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }else{
                    console.log("error", err.response)
                    window.location.reload()
                }
                
            }
        );
    }

    const handleSearchUser = async (id) => {
        const requestOptions = {
            method: "GET",
            url: domain + "admin/users/"+id,
            withCredentials: true,
            headers: {
            "accept": "application/json",
            }
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    // console.log(res.data)
                    setUserObjSearch(res.data)
                    setFilter(true)
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    // navigate("/")
                }else{
                    console.log("error", err.response)
                    // window.location.reload()
                }
                setFilter(false)
                
            }
        );
    }

    useEffect(async ()=>{
        //fetch users
        const requestOptions = {
            method: "GET",
            url: domain + "admin/users/all",
            withCredentials: true,
            headers: {
            "accept": "application/json",
            },
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    let user_ids = []
                    res.data.forEach(function (item){
                        user_ids.push(item.id)
                    });
                    sort_by_user_id(user_ids, res.data)
                }
        ).catch(
            err => {
                if(err.response){
                    if (err.response.status == 401){
                        navigate("/")
                    }
                }
                else{
                    console.log("error", err)
                }
            }
        );
        setLoad(true);
    }, []);

    var ok =[{
        "id": 20,
        "password": "$2b$12$nw2XPhabF7lG5g/dHPBmjO/YxdDptN6I./o/j4Zx36ia1/eQ59cjq",
        "email": "asd@AS.COM",
        "is_active": true,
        "created_at": "2021-10-26T23:10:36.544148"
      }];
    
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            if(userIdFromSearch == ""){
                setFilter(false)
            }else{
                handleSearchUser(userIdFromSearch)

            }
        }
    }

    const sort_by_user_id = async (toSort, template_array) => {
        let order_by_id_array = []
        for (var i = 0; i < toSort.length; i++) {
            toSort[i] = [toSort[i], i];
          }
          toSort.sort(function(left, right) {
            return left[0] < right[0] ? -1 : 1;
          });
          toSort.sortIndices = [];
          for (var j = 0; j < toSort.length; j++) {
            toSort.sortIndices.push(toSort[j][1]);
            toSort[j] = toSort[j][0];
          }
          console.log(toSort.sortIndices);
        
        toSort.sortIndices.forEach(function(item, index, array){
            order_by_id_array.push(template_array[item])
        })
        setUser1(order_by_id_array)
    }

    

    return (
        <div>
            <Navbar/>
            {user1 ? (
                <>
                {load ? (
                <div className={sidebarActive ? "layout__content" : "layout__content-main"}>

                <br></br>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card__header">
                                    <h3>users</h3>
                                </div>
                            <div className="card__body">

                            <MDBCol md="6">
                                <form className="form-inline mt-4 mb-4" onSubmit={e => { e.preventDefault();}}>
                                    <MDBIcon icon="search" onClick={()=>{
                                        if(userIdFromSearch == ""){
                                            setFilter(false)
                                        }else{
                                            handleSearchUser(userIdFromSearch)

                                        }

                                    }}/>
                                    <input
                                        className="form-control form-control-sm ml-3 w-75"
                                        type="number"
                                        placeholder="Search User ID"
                                        aria-label="Search"
                                        value={userIdFromSearch}
                                        onChange={(e) => setUserIdFromSearch(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                </form>
                            </MDBCol>
                                {filter ? (
                                    <>
                                        <Button variant="outline-dark" size="sm" onClick={()=> setFilter(false)}>Clear Search Results</Button>
                                        <User data={[userObjSearch]}/>
                                    </>
                                ) : (
                                    <Table
                                    limit='10'
                                    headData={customerTableHead}
                                    renderHead={(item, index) => renderHead(item, index)}
                                    bodyData={user1}
                                    renderBody={(item, index) => renderBody(item, index)}
                                />
                                )}
                            <div
                                className="d-flex align-items-center justify-content-center">
                                <Button variant="outline-dark" onClick= {()=>{navigate("create_user")}}>Create User</Button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                ) : `Loading` }
                </>
            ) : <UserNoAdmin/>}

        </div>
    )
}

export default Users
