import { createContext, useState, useContext } from "react"

const DomainContext = createContext(null);

export const useDomainC = () =>{
    return useContext(DomainContext)
}

export const DomainProvider = ({children}) => {
    // prod
    const domain = "https://traffic.telenavis.com/"
    const domain_host = domain

    // dev
    // const domain = "http://localhost:8000/"
    // const domain_host = "http://localhost:3000"
    
    const value = {
        domain,
        domain_host
    }

    return(
        <DomainContext.Provider value={value}>
            {children}
        </DomainContext.Provider>
    )
}
