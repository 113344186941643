import React, {useRef, useState, useEffect} from 'react'
import { Form, Card, Button, Alert, Container} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { useSideC } from '../contexts/SidebarContext';
import Navbar from '../components/Navbar';
import axios from 'axios';
import { useDomainC } from '../contexts/Domain';
import { useAuth } from '../contexts/AuthContext';

const CreateUser = () => {
    const {sidebarActive} = useSideC()
    const [error, setError] = useState("")

    const emailRef = useRef();
    const passwordRef = useRef();
    const navigate = useNavigate()
    const {domain} = useDomainC()
    const{isAdmin} = useAuth

    const createUser = async () => {
        const requestOptions = {
            method: "POST",
            url: domain + "admin/users/new",
            withCredentials: true,
            headers: {
            "accept": "application/json",
            "Content-Type": "application/json"
            },
            data: JSON.stringify({
                "email": emailRef.current.value,
                "password": passwordRef.current.value,
            })
        };

        await axios(requestOptions).
        then(
            res => 
                {
                    alert(`User created with ID:  ${res.data.id}`)
                    navigate("/users")
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }else{
                    console.log("error", err.response.data.detail)
                    setError(err.response.data.detail)
                }
            }
        ); 
    }


    const handleCreateUser = async (e) =>{
        e.preventDefault();
        await  createUser()
    }

    useEffect(() => {
        if(!isAdmin){
            navigate("/dashboard")
        }
    }, [])
    return (
        <div>
            <Navbar/>
            <div className={sidebarActive ? "layout__content" : "layout__content-main"}>
            
            <br></br>
            <div className="row">
                <div className="col-12">
                    <div className="card">

                        <Container
                            className="d-flex  justify-content-center"
                        >
                            <div className="w-100" style={{ maxWidth: "400px" }}  >
                                <Card>
                                    <Card.Body>
                                        <h2 className="text-center mb-4">Create User</h2>
                                        {error  && <Alert variant="danger">{error}</Alert>}
                                        
                                        <Form onSubmit={handleCreateUser}>
                                            <Form.Group id="email">
                                                <Form.Label>Email:</Form.Label>
                                                <Form.Control type="text" ref={emailRef} required />
                                            </Form.Group><br/>

                                            <Form.Group id="password">
                                                <Form.Label>Password:</Form.Label>
                                                <Form.Control type="password" ref={passwordRef} required />
                                            </Form.Group><br/>

                                            
                                            <Button className="w-100" type="submit">Create User</Button>
                                        </Form>
                                    </Card.Body>
                                </Card>

                            </div>
                        </Container>
            

                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default CreateUser
