import React, {useEffect, useState} from 'react'
import { useAuth } from '../contexts/AuthContext';
import { useSideC } from '../contexts/SidebarContext';

const UserNoAdmin = () => {
    const {sidebarActive} = useSideC()

    return (
        <>
            <div className={sidebarActive ? "layout__content" : "layout__content-main"}>

                <br></br>
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card__header">
                                    <h3>users</h3>
                                </div>
                            <div className="card__body">
                                {localStorage.getItem("is_admin") === "false" ? (
                                    <>
                                        <h5>To see all users you need admin permissions</h5>
                                    </>
                                ) : (
                                    <h5>Loading</h5>
                                )}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default UserNoAdmin
