
import Navbar, {sidebar} from '../components/Navbar';


import React, {useEffect, useState} from 'react'
import '../assets/css/layout.css'

import { Link } from 'react-router-dom'

import Chart from 'react-apexcharts'

import { useSelector } from 'react-redux'

import StatusCard from '../components/status-card/StatusCard'

import Table from '../components/table/Table'

import Badge from '../components/badge/Badge'

import statusCards from '../assets/JsonData/status-card-data.json'
import { useSideC } from '../contexts/SidebarContext';
import { useDomainC } from '../contexts/Domain';
import axios from 'axios';
import {useNavigate} from 'react-router'
import { useAuth } from '../contexts/AuthContext';

const chartOptions = {
    series: [{
        name: 'Online Users',
        data: [40,70,20,90,36,80,30,91,60]
    }, {
        name: 'Store users',
        data: [40, 30, 70, 80, 40, 16, 40, 20, 51, 10]
    }],
    options: {
        color: ['#6ab04c', '#2980b9'],
        chart: {
            background: 'transparent'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep']
        },
        legend: {
            position: 'top'
        },
        grid: {
            show: false
        }
    }
}

const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }


const topCustomers = {
    head: [
        'user',
        'total apis',
        'total hits'
    ],
    body: [
        {
            "username": "mike dev",
            "order": "490",
            "price": "15,870"
        },
        {
            "username": "dion dev",
            "order": "250",
            "price": "12,251"
        },
        {
            "username": "joee dev",
            "order": "120",
            "price": "10,840"
        },
        {
            "username": "vag dev",
            "order": "110",
            "price": "9,251"
        },
        {
            "username": "markos dev",
            "order": "80",
            "price": "8,840"
        }
    ]
}

const renderCusomerHead = (item, index) => (
    <th key={index}>{item}</th>
)

const renderCusomerBody = (item, index) => (
    <tr key={index}>
        <td>{item.username}</td>
        <td>{item.order}</td>
        <td>{item.price}</td>
    </tr>
)

const latestOrders = {
    header: [
        "user id",
        "apikey",
        "domain",
        "last updated",
        "queries",
        "status"
    ],
    body: [
        {
            id: "1711",
            user: "admin",
            date: "17 Jun 2021",
            status: "active"
        },
        {
            id: "1712",
            user: "dev mike",
            date: "1 Jun 2021",
            status: "not active"
        },
        {
            id: "1713",
            user: "dev gk",
            date: "27 Jun 2021",
            status: "active"
        },
        {
            id: "1712",
            user: "dev mike",
            date: "1 Jun 2021",
            status: "not active"
        },
        {
            id: "1713",
            user: "dev gk",
            date: "27 Jun 2021",
            status: "active"
        }
    ]
}

const orderStatus = {
    "shipping": "primary",
    "pending": "warning",
    "true": "success",
    "false": "danger"
}

const renderOrderHead = (item, index) => (
    <th key={index}>{item}</th>
)

const renderOrderBody = (item, index) => (

    // 'user id",
    // "apikey",
    // "domain",
    // "status"
    // "last updated",
    // "queries",
    <tr key={index}>
        <td>{item.user_id}</td>
        <td>{item.api_key}</td>
        <td>{item.domain}</td>
        <td>{formatDate(item.updated_at)}</td>
        <td>{`${item.total_queries}/${item.max_queries}`}</td>
        <td>
            <Badge 
                type={orderStatus[item.is_active.toString()]} 
                content={item.is_active == true ? (
                    "active"
                ): "not active"}
            />
        </td>
    </tr>
)

const Dashboard = () => {

    const themeReducer = useSelector(state => state.ThemeReducer.mode)
    const {sidebarActive} = useSideC();
    const {domain} = useDomainC()
    const {user_name} = useAuth()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [myApis, setMyApis] = useState()
    // const [loading, setLoading] = useState(true)

    useEffect(async ()=>{
        const requestOptions = {
            method: "GET",
            url: domain + "admin/apikey/owned/",
            withCredentials: true,
            headers: {
            "accept": "application/json",
            },
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    setMyApis(res.data)
                    // latestOrders.body = [
                    //     res.data[0],
                    //     res.data[1]
                    // ]
                    
                    if(res.data.length >= 10){
                        latestOrders.body = res.data.slice(0,10)
                    }else{
                        latestOrders.body = res.data
                    }
                    
                    console.log(res.data)
                    setLoading(false)
                }
        ).catch(
            err => {
                if (err.response){
                    if (err.response.status == 401){
                        navigate("/")
                    }
                    else{
                        console.log("error", err.response)
                    }
                }
                else{
                    console.log("Dahboard error",err)
                }
            }
        );
    }, []);

    return (
        
        <div>

        <Navbar/>
            <div className={sidebarActive ? "layout__content" : "layout__content-main"}>
                {localStorage.getItem("username") ? (
                    <div className="user_logged">
                        Welcome {localStorage.getItem("username")}
                    </div>
                ):""}
                
                {!sidebarActive ? (
                <h2 className="page-header">Dashboard</h2> ) : <><br></br><br></br></>}
                <div className="row">
                    <div className="col-6">
                        <div className="row">
                            {
                                statusCards.map((item, index) => (

                                    <div className="col-6" key={index}>
                                        <StatusCard
                                            icon={item.icon}
                                            count={item.count}
                                            title={item.title}
                                        />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="card full-height">
                            {/* chart */}
                            <Chart
                                options={themeReducer === 'theme-mode-dark' ? {
                                    ...chartOptions.options,
                                    theme: { mode: 'dark'}
                                } : {
                                    ...chartOptions.options,
                                    theme: { mode: 'light'}
                                }}
                                series={chartOptions.series}
                                type='line'
                                height='100%'
                            />
                        </div>
                    </div>
                    
                    {loading ? <>loading</> : (
                    <div className="col-12">
                        <div className="card">
                            <div className="card__header">
                                <h3>latest apis</h3>
                            </div>
                            <div className="card__body">
                                <Table
                                    headData={latestOrders.header}
                                    renderHead={(item, index) => renderOrderHead(item, index)}
                                    bodyData={latestOrders.body}
                                    renderBody={(item, index) => renderOrderBody(item, index)}
                                />
                            </div>
                            <div className="card__footer">
                                <Link to='/apis'>view all</Link>
                            </div>
                        </div>
                    </div>
                    )}
                </div>
                </div>
        </div>
    )
}

export default Dashboard