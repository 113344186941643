import { createContext, useState, useContext } from "react"

const SidebarContext = createContext(null);

export const useSideC = () =>{
    return useContext(SidebarContext)
}

export const SidebarProvider = ({children}) => {
    const [sidebarActive, setSidebarActive] = useState(false);

    const disableSidebar = () => {
        setSidebarActive(false)
    }

    const enableSidebar = () =>{
        setSidebarActive(true)
    }
    
    const value = {
        sidebarActive,
        disableSidebar,
        enableSidebar
    }

    return(
        <SidebarContext.Provider value={value}>
            {children}
        </SidebarContext.Provider>
    )
}
