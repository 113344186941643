import React, {useRef, useState} from 'react'
import { Form, Card, Button, Alert, Container} from 'react-bootstrap'
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';

export const Signup = () => {

    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordConfirmRef = useRef();
    const {error, signup} = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()


    const handleSubmit = async (e) => {
        e.preventDefault();

        if(passwordRef.current.value != passwordConfirmRef.current.value){
            console.log("not same passwords")
        }else{
            console.log(emailRef.current.value, passwordRef.current.value)
            await signup(emailRef.current.value, passwordRef.current.value)
        }
      };

      return (
        <>
        <Container
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "100vh" }}
        >
            <div className="w-100" style={{ maxWidth: "400px" }}  >
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Log In</h2>
                        {error  && <Alert variant="danger">{error}</Alert>}
                        
                        <Form onSubmit={handleSubmit}>
                            <Form.Group id="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" ref={emailRef} required />
                            </Form.Group>
                            <br/>

                            <Form.Group id="password">
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" ref={passwordRef} required />
                            </Form.Group><br/>

                            <Form.Group id="passwordConf">
                                <Form.Label>Password Confirmation </Form.Label>
                                <Form.Control type="password" ref={passwordConfirmRef} required />
                            </Form.Group><br/>

                            
                            {!loading && <Button className="w-100" type="submit">SignUp</Button>}
                        </Form>
                    </Card.Body>
                </Card>

                <div className="w-100 text-center mt-2">
                    Already have an account? <Link to="/">Log In</Link>
                </div>
            </div>
        </Container>
            
        </>
    )
}

export default Signup;