import React, {useEffect} from 'react'
import Navbar from '../components/Navbar';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import { useDomainC } from '../contexts/Domain';


const About = () => {
    const navigate = useNavigate();
    const {domain} = useDomainC()

    useEffect(async ()=>{
        const requestOptions = {
            method: "GET",
            url: domain+ "admin/logout",
            withCredentials: true,
            headers: {
              "accept": "application/json",
            },
          };
      
          await axios(requestOptions).
          then(
              res => 
                  {
                      console.log("LogOut")
                      localStorage.removeItem("is_admin")
                      localStorage.removeItem("username")
                      localStorage.removeItem("user_id")
                      navigate('/')
                  }
          ).catch(
              err => {
                  if (err.response.status === 401){
                      navigate("/")
                  }
                  console.log(err.response)
                  navigate("/")
              }
          );

    }, []);


    return (
        <div>
            <Navbar/>
            Logging Out
        </div>
    )
}

export default About;