

import React, {useRef, useState} from 'react'
import { Form, Card, Button, Alert, Container} from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom';
import { useSideC } from '../contexts/SidebarContext';
import Navbar from '../components/Navbar';
import axios from 'axios';
import { useDomainC } from '../contexts/Domain';
import { useAuth } from '../contexts/AuthContext';


const CreateApi = () => {
    const {sidebarActive} = useSideC()
    const {userID, isAdmin} = useAuth()
    const [error, setError] = useState("")

    const userIdRef = useRef();
    const domainRef = useRef();
    const maxQueriesRef = useRef();
    const navigate = useNavigate()
    const {domain} = useDomainC()

    const createApi = async () =>{
        const requestOptions = {
            method: "POST",
            url: domain + "admin/apikey/new",
            withCredentials: true,
            headers: {
            "accept": "application/json",
            "Content-Type": "application/json"
            },
            data: JSON.stringify({
                "domain": domainRef.current.value,
                "user_id": userIdRef.current.value,
                "max_queries": maxQueriesRef.current.value,
                "is_active": true,
                "total_queries": 0
            })
        };

        await axios(requestOptions).
        then(
            res => 
                {
                    alert(`api ${res.data.api_key} is created`)
                    navigate("/apis")
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }else{
                    console.log("error", err.response)
                    setError(err.response.statusText)
                }
            }
        );        
    } 


    const handleCreateAPI = async (e) => {
        e.preventDefault();
       await  createApi()

    }


    
    return (
        <div>
            <Navbar/>
            <div className={sidebarActive ? "layout__content" : "layout__content-main"}>
            
            <br></br>
            <div className="row">
                <div className="col-12">
                    <div className="card">

                        <Container
                            className="d-flex  justify-content-center"
                        >
                            <div className="w-100" style={{ maxWidth: "400px" }}  >
                                <Card>
                                    <Card.Body>
                                        <h2 className="text-center mb-4">Create API Key</h2>
                                        {error  && <Alert variant="danger">{error}</Alert>}
                                        
                                        <Form onSubmit={handleCreateAPI}>
                                            <Form.Group id="domain">
                                                <Form.Label>Domain:</Form.Label>
                                                <Form.Control type="text" placeholder="seperate domains by comma: ex1, ex2" ref={domainRef} required />
                                            </Form.Group><br/>
                                            
                                            {isAdmin ? (<>
                                            <Form.Group id="user_id">
                                                <Form.Label>User ID:</Form.Label>
                                                    <Form.Control type="number" placeholder="user's id" ref={userIdRef} required />
                                            </Form.Group>
                                            <br/>
                                            </>
                                            ) : <></>}

                                            {isAdmin ? (<>
                                            <Form.Group id="passwordConf">
                                                <Form.Label>Max Queries:</Form.Label>
                                                <Form.Control type="number" placeholder="how much api calls is allowed to make" ref={maxQueriesRef} required />
                                            </Form.Group>
                                            <br/>
                                            </>
                                            ) : <></>}

                                            
                                            <Button className="w-100" type="submit">Create ApiKey</Button>
                                        </Form>
                                    </Card.Body>
                                </Card>

                            </div>
                        </Container>
            

                    </div>
                </div>
            </div>
            </div>

        </div>
    )
}


export default CreateApi
