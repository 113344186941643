import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Users from './pages/Users'
import About from './pages/About';
import Apis from './pages/Apis';
import Signup from './components/auth/Signup';
import Login from './components/auth/Login'
import UserTable from './components/usertable/UserTable'
import {AuthProvider} from  './contexts/AuthContext'
import {SidebarProvider} from './contexts/SidebarContext'
import { DomainProvider } from './contexts/Domain';
import CreateApi from './pages/CreateApi';
import CreateUser from './pages/CreateUser';


function App() {
  return (
    <>
      <Router>
        <DomainProvider>
        <AuthProvider>
        <SidebarProvider>
          <Routes>
            <Route path='/' element={<Login/>} />
            <Route path='/signup' element={<Signup/>} />
            
              <Route path='/dashboard' exact element={<Dashboard/>} />
              <Route path='/apis' element={<Apis/>} />
              <Route path='/users' element={<Users/>} />
              <Route path='/users/create_user' element={<CreateUser/>}/>
              <Route path='/apis/create_api' element={<CreateApi/>} />
              {/* <Route path='/users' element={<UserTable/>} /> */}
              <Route path='/logout' element={<About/>} />
           
          </Routes>
          </SidebarProvider>
        </AuthProvider>
        </DomainProvider>
      </Router>
    </>
  );
}

export default App;
