import React, { useState, useEffect } from 'react'
import Navbar from '../components/Navbar';
import UserTable from '../components/usertable/UserTable';
import Table from '../components/table/Table'
import getAllUSers from '../assets/JsonData/getAllUsers.json'
import { useSideC } from '../contexts/SidebarContext';
import { useNavigate } from 'react-router';
import axios from 'axios';
import { MDBCol, MDBFormInline, MDBBtn, MDBIcon } from "mdbreact";
import ApisTable from '../components/ApisTable';
import {Button} from 'react-bootstrap'
import { useDomainC } from '../contexts/Domain';
import '../assets/css/layout.css'

const customerTableHead = [
    'user id',
    'api key',
    'domain',
    'last update',
    'requests',
    'active',
]
const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" }
    return new Date(dateString).toLocaleDateString(undefined, options)
  }


const renderHead = (item, index) => <th key={index}>{item}</th>

const Apis = () => {
    const {sidebarActive} = useSideC()
    const [apis, setApis] = useState()
    const [apiID, setApiID] = useState("")
    const [apisf, setApisf] = useState()
    const [load, setLoad] = useState(false)
    const [fetched, setFetched] = useState(false)
    const navigate = useNavigate()
    const [error, setError] = useState("")
    const {domain} = useDomainC()
    const [myApis, setMyApis] = useState()
    const [noKeys, setNoKeys] = useState(false)
    const [noKeyError, setNoKeyError] = useState()

    const handleFetchApi = async (api) =>{
        var url_path = "";
        
        if(!isNaN(api)){
            url_path = `${domain}admin/apikey/user/${api}`
        }else{
            url_path = `${domain}admin/apikey/${api}`
        }
        const requestOptions = {
            method: "GET",
            url: url_path,
            withCredentials: true,
            headers: {
            "accept": "application/json",
            },
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    setApisf(res.data)
                    setFetched(true);
                    setLoad(false)
                    setNoKeys(false)
                    if(res.data.length == 0){
                        setNoKeys(true)
                    }
                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }
                else if (err.response.status == 404){
                    setNoKeys(true)
                }
                else{
                    console.log("error", err.response)
                }
                setFetched(false)
                setError(err.response.statusText)
                setApisf([])
            }
        );
    } 

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            setLoad(false)
            setFetched(false)
            await handleFetchApi(apiID)
        }
    }

    useEffect(async ()=>{
        const requestOptions = {
            method: "GET",
            url: domain + "admin/apikey/owned/",
            withCredentials: true,
            headers: {
            "accept": "application/json",
            },
        };
    
        await axios(requestOptions).
        then(
            res => 
                {
                    setMyApis(res.data)
                    setNoKeys(false)
                    console.log("fetch my api", res.data)

                }
        ).catch(
            err => {
                if (err.response.status == 401){
                    navigate("/")
                }
                else if (err.response.status == 404){
                    setNoKeys(true)
                }
                else{
                    console.log("error", err.response)
                }
            }
        );

        setLoad(true);

    }, []);

    
    return (
        <div>
            <Navbar/>
            <div className={sidebarActive ? "layout__content" : "layout__content-main"}>
            
            <br></br>
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card__header">
                            <h3>Apis</h3>
                        </div>

                        {/* 
                            LOGIC EXPLANATION
                            if nokeys = true (user searched something with no result)
                                displays no keys
                            else nokeys = false
                                search bar
                                if some request is done
                                    we show the table
                                else
                                    error
                        */}
                        {!noKeys ? (
                            <>

                            <MDBCol md="6">
                                <form className="form-inline mt-4 mb-4" onSubmit={e => {
                                    e.preventDefault();
                                }}>
                                    <MDBIcon icon="search" onClick={async()=>{
                                        setLoad(false)
                                        setFetched(false)
                                        await handleFetchApi(apiID)
                                    }}/>
                                    <input
                                        className="form-control form-control-sm ml-3 w-75"
                                        type="text"
                                        placeholder="Search Api Key"
                                        aria-label="Search"
                                        value={apiID}
                                        onChange={(e) => setApiID(e.target.value)}
                                        onKeyDown={handleKeyDown}
                                    />
                                </form>
                            </MDBCol>

                        
                            {load ? (
                                <ApisTable data={myApis} refreshMethod ={null}/>
                            ) : (
                                <>
                                    {fetched && apisf ? (
                                    <>
                                        <Button variant="outline-dark" size="sm" onClick= {()=>{setLoad(true)}}>Clear Search!</Button>
                                        <ApisTable data={load ? (myApis): (apisf)} refreshMethod ={handleFetchApi}/>
                                    </>
                                    ) :""}
                                </>
                            )}</>
                        ) : (
                            <>
                                <div className="center_text_api">No Keys Found</div>
                                <Button variant="outline-dark" size="sm" onClick= {()=>{
                                    setNoKeys(false);
                                    setLoad(true);
                                }}>Clear Search</Button>
                            </>
                            )}

                        <Button variant="outline-dark" onClick= {()=>{navigate("create_api")}}>Create ApiKey</Button>

                    </div>
                </div>
            </div>
            </div>

        </div>
    )
}


export default Apis
