import React from 'react'
import * as AiIcons from 'react-icons/ai'
import * as MdIcons from 'react-icons/md'
import * as HiIcons from 'react-icons/hi'
import * as BsIcons from 'react-icons/bs'

export const SidebarData = [
    {
        title: 'Dashboard',
        path: '/dashboard',
        icon: <MdIcons.MdOutlineDashboardCustomize/>,
        cName: 'nav-text'
    },
    {
        title: 'Users',
        path: '/users',
        icon: <HiIcons.HiUserGroup/>,
        cName: 'nav-text'
    },
    {
        title: 'Apis',
        path: '/apis',
        icon: <BsIcons.BsKey/>,
        cName: 'nav-text'
    },
    {
        title: 'Logout',
        path: '/logout',
        icon: <AiIcons.AiOutlineInfoCircle/>,
        cName: 'nav-text'
    },
]

